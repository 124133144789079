import { FC } from "react"
import * as Sentry from "@sentry/react"
import {
  ContractDetails,
  ContractVersion,
  getContractByVersionId,
} from "@appia/api"
import { Card, DownloadIcon, IconButton } from "@appia/ui-components"
import { formatDateToDDMMYYYY } from "src/utils/dates"
import { generateDocumentName } from "src/utils/documents"
import { prettyPrintLongDateString } from "src/utils/prettyPrinters"
import useApiClient from "src/contexts/ApiClientContext"
import { generatePDF } from "./GeneratePDF/GeneratePDF"

interface ContractPreviousVersionsProps {
  contractData: ContractDetails
}

const ContractPreviousVersions: FC<ContractPreviousVersionsProps> = ({
  contractData,
}) => {
  const apiClient = useApiClient()

  const handleDownloadContractVersion = async (
    contractId: string,
    versionId: number,
    version: number,
  ): Promise<void> => {
    try {
      const response = await getContractByVersionId(
        apiClient,
        contractId,
        versionId,
      )

      if (response.data) {
        generatePDF(response.data, version)
      }
    } catch (error) {
      if (error instanceof Error) {
        Sentry.captureException(error)
      }
    }
  }

  return (
    <section className="pt-4 pb-8" aria-labelledby="Previous Contracts">
      <h3 id="documentsLabelId" className="mb-2 text-xl">
        Previous Versions
      </h3>
      <Card className="mb-4">
        <div className="grid grid-cols-3 items-center">
          <div className="font-bold">Name</div>
          <div className="flex items-center justify-center pl-3 font-bold">
            Process date
          </div>
          <div></div> {/* Empty div for spacing and visual balance */}
        </div>
        {contractData.versions &&
          contractData.versions.map(
            (
              {
                completedAt,
                createdAt,
                versionId,
                contractId,
              }: ContractVersion,
              index: number,
            ) => {
              // Contract Versions data is BE pre-sorted by DESC completedAt
              const latestVersion = contractData.versions.length
              const version = latestVersion - index

              const completedAtDate = completedAt
                ? formatDateToDDMMYYYY(completedAt)
                : "UNKNOWN_COMPLETED_DATE"
              const fileName = generateDocumentName(
                contractData.contract.header.insured,
                completedAtDate,
                version,
              )
              return (
                <div
                  key={versionId}
                  className="mt-3 grid grid-cols-3 items-center"
                  data-cy="contract-versions-row"
                >
                  <div>{fileName}</div>
                  <div className="flex justify-center">
                    {prettyPrintLongDateString(createdAt)}
                  </div>
                  <div className="flex justify-end">
                    <IconButton
                      icon={<DownloadIcon />}
                      size={6}
                      label={`Download document ${contractId}`}
                      onClick={() =>
                        handleDownloadContractVersion(
                          contractId,
                          versionId,
                          version,
                        )
                      }
                    />
                  </div>
                </div>
              )
            },
          )}
      </Card>
    </section>
  )
}

export default ContractPreviousVersions
