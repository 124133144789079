import { useEffect } from "react"
import { useLocation } from "react-router-dom"
import { AmplitudeClient, Identify, default as amplitude } from "amplitude-js"

import type { KiAnswer, KiQuote, PBQAQuestion, PBQASurvey } from "@appia/api"

const client: AmplitudeClient = amplitude.getInstance()

let userIdSet = false

export const init = (apiKey: string): void => client.init(apiKey)

export const setUser = ({
  userId,
  teamId,
}: {
  userId: string
  teamId?: string
}): void => {
  if (!userIdSet) {
    client.setUserId(userId)
    userIdSet = true
  }

  const identify: Identify = new amplitude.Identify()
  if (teamId) {
    client.identify(identify.set("teamId", teamId))
  } else {
    client.identify(identify.unset("teamId"))
  }
}

const useRunOnce = (fn: () => void): void =>
  useEffect(() => {
    fn()
    // Intentionally run this a single time
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

/**
 * General events
 */
interface PageViewEvent {
  pageName: string
}

export const useLogPageView = (ev: PageViewEvent): void => {
  const location = useLocation()
  useRunOnce(() =>
    client.logEvent("PageViewed", { ...ev, pageUrl: location.pathname }),
  )
}

interface ButtonClickEvent {
  buttonName: string
  containerName: string
  pageName?: string
  linkHref?: string
}

export const logButtonClick = (ev: ButtonClickEvent): void => {
  client.logEvent("ButtonClicked", ev)
}

/**
 * Table events
 */
interface TableFilterEvent {
  tableId: string
  field: string
  value: string | null
}

export const logTableFilter = (ev: TableFilterEvent): void => {
  client.logEvent("TableFiltered", ev)
}

interface TableSortEvent {
  tableId: string
  column: string
  direction: "asc" | "desc"
}

export const logTableSort = (ev: TableSortEvent): void => {
  client.logEvent("TableSorted", ev)
}

/**
 * File events
 */
interface FileDownloadEvent {
  fileName: string
}

export const logFileDownload = (ev: FileDownloadEvent): void => {
  client.logEvent("FileDownloaded", ev)
}

/**
 * PBQA events
 */
interface PBQAOpenedEvent {
  pbqaId: string
}

export const useLogPBQAOpen = (ev: PBQAOpenedEvent): void => {
  useRunOnce(() => client.logEvent("PBQAOpened", ev))
}

interface PBQAUploadStartedEvent {
  pbqaId: string
  documentId: string
  mimetype: string
}

export const logPBQAUploadStart = (ev: PBQAUploadStartedEvent): void => {
  client.logEvent("PBQAUploadStarted", ev)
}

interface ContractDocumentUploadStartedEvent {
  fileId: string
  umr: string
  externalId: string
  type: string
  mimetype: string
}

export const logContractDocumentUploadStart = (
  ev: ContractDocumentUploadStartedEvent,
): void => {
  client.logEvent("DocumentUploadHSStarted", ev)
}

interface PBQAUploadCompletedEvent {
  documentId: string
}

export const logPBQAUploadComplete = (ev: PBQAUploadCompletedEvent): void => {
  client.logEvent("PBQAUploadCompleted", ev)
}

interface PBQAUploadCompletedEvent {
  documentId: string
}

interface ContractDocumentUploadCompletedEvent {
  fileId: string
}

export const logContractDocumentUploadComplete = (
  ev: ContractDocumentUploadCompletedEvent,
): void => {
  client.logEvent("DocumentUploadHSCompleted", ev)
}

interface PBQAAnswerChangeEvent {
  pbqaId: string
  surveyId: PBQASurvey["id"]
  questionId: PBQAQuestion["id"]
}

export const logPBQAAnswerChange = (ev: PBQAAnswerChangeEvent): void => {
  client.logEvent("PBQAAnswerChanged", ev)
}

interface PBQAAnswerAcceptEvent {
  pbqaId: string
  surveyId: PBQASurvey["id"]
  questionId: PBQAQuestion["id"]
  accepted: boolean
}

export const logPBQAAnswerAccept = (ev: PBQAAnswerAcceptEvent): void => {
  client.logEvent("PBQAAnswerAccepted", ev)
}

interface PBQANoteAddEvent {
  pbqaId: string
  surveyId: PBQASurvey["id"]
  questionId: PBQAQuestion["id"]
}

export const logPBQANoteAdd = (ev: PBQANoteAddEvent): void => {
  client.logEvent("PBQANoteAdded", ev)
}

interface PBQANoteChangeEvent {
  pbqaId: string
  surveyId: PBQASurvey["id"]
  questionId: PBQAQuestion["id"]
}

export const logPBQANoteChange = (ev: PBQANoteChangeEvent): void => {
  client.logEvent("PBQANoteChanged", ev)
}

interface PBQANoteRemoveEvent {
  pbqaId: string
  surveyId: PBQASurvey["id"]
  questionId: PBQAQuestion["id"]
}

export const logPBQANoteRemove = (ev: PBQANoteRemoveEvent): void => {
  client.logEvent("PBQANoteRemoved", ev)
}

interface PBQAValueCopyEvent {
  pbqaId: string
  surveyId: PBQASurvey["id"]
  questionId: PBQAQuestion["id"]
  // This isn't technically a KiAnswer, but we want the same shape
  extractedValue: KiAnswer
  kiValue: KiAnswer
}

export const logPBQAValueCopy = (ev: PBQAValueCopyEvent): void => {
  client.logEvent("PBQAValueCopied", ev)
}

interface PBQASaveEvent {
  pbqaId: string
  surveyId: PBQASurvey["id"]
}

export const logPBQASave = (ev: PBQASaveEvent): void => {
  client.logEvent("PBQASaved", ev)
}

interface PBQAConfirmEvent {
  pbqaId: string
  surveyId: PBQASurvey["id"]
  surveyName: string
}

export const logPBQAConfirm = (ev: PBQAConfirmEvent): void => {
  client.logEvent("PBQAConfirmed", ev)
}

interface PBQAExportEvent {
  pbqaId: string
  surveyId: PBQASurvey["id"]
}

export const logPBQAExport = (ev: PBQAExportEvent): void => {
  client.logEvent("PBQAExported", ev)
}

interface PBQAOverliningModalOpenEvent {
  pbqaId: string
  surveyId: PBQASurvey["id"]
}

export const logPBQAOverliningModalOpened = (
  ev: PBQAOverliningModalOpenEvent,
): void => {
  client.logEvent("PBQAOverliningModalOpened", ev)
}

interface PBQAOverliningQuoteSelectEvent {
  pbqaId: string
  surveyId: PBQASurvey["id"]
  quoteLineId: KiQuote["quoteLineId"]
}

export const logPBQAOverliningQuoteSelect = (
  ev: PBQAOverliningQuoteSelectEvent,
): void => {
  client.logEvent("PBQAOverliningQuoteSelect", ev)
}

interface PBQAArchiveEvent {
  pbqaId: string
}

export const logPBQAArchive = (ev: PBQAArchiveEvent): void => {
  client.logEvent("PBQAArchived", ev)
}

interface PBQAUnarchiveEvent {
  pbqaId: string
}

export const logPBQAUnarchive = (ev: PBQAUnarchiveEvent): void => {
  client.logEvent("PBQAUnarchived", ev)
}

interface PBQADuplicateEvent {
  pbqaId: string
}

export const logPBQADuplicate = (ev: PBQADuplicateEvent): void => {
  client.logEvent("PBQADuplicated", ev)
}
