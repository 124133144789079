import { FC, ReactElement, ReactNode } from "react"
import "./ScreenTemplate.css"

import ErrorBoundary from "./ScreenErrorBoundary"
import {
  ArrowLeftIcon,
  Header,
  HeaderLink,
  IconLink,
} from "@appia/ui-components"
import classNames from "classnames"

import { fullNameOrEmail } from "src/utils/users"
import * as RD from "@appia/remote-data"
import { useGetCurrentUser, useGetTeam } from "src/swr"

import { logButtonClick } from "src/amplitude"

const navLinks = (supportedEndorsementFlow: boolean): HeaderLink[] => [
  { name: "PBQA", href: "/pbqa" },
  {
    name: "Contract processing",
    href: "/contract",
  },
  {
    name: "Endorsements",
    href: "/endorsements",
    hidden: !supportedEndorsementFlow,
  },
  { name: "Teams", href: "/teams" },
  { name: "Settings", href: "/settings" },
]

const userLinks: HeaderLink[] = [{ name: "Log out", href: "/logout" }]

type ScreenTemplateLayout =
  | { type: "regular"; backPath?: string; actionButton?: ReactElement }
  | { type: "simple" }

interface Props {
  children: ReactNode
  noMaxContentWidth?: boolean
  noPadding?: boolean
  pageTitle: string
  layout: ScreenTemplateLayout
}

const ScreenTemplate: FC<Props> = ({
  children,
  noMaxContentWidth = false,
  noPadding = false,
  pageTitle,
  layout,
}) => {
  const { request: userRequest } = useGetCurrentUser()
  const me = RD.isSuccess(userRequest) ? userRequest.data : undefined
  const user = me?.user
  const supportedEndorsementFlow = me?.supportedFlows.endorsements ?? false
  const { request: teamRequest } = useGetTeam(user?.teamId || null)
  const team = RD.isSuccess(teamRequest) ? teamRequest.data : undefined

  const userData = user && {
    displayName: fullNameOrEmail(user),
    teamName: team?.name,
  }

  return (
    <div className="ScreenTemplate h-screen">
      <Header
        navLinks={navLinks(supportedEndorsementFlow)}
        userLinks={userLinks}
        user={userData}
        onLinkClick={({ name, href }) => {
          logButtonClick({
            buttonName: name,
            containerName: "Header",
            linkHref: href,
          })
        }}
      />

      {layout.type === "regular" && (
        <header className="bg-white shadow forced-colors:border-b">
          <div className="mx-auto flex h-16 max-w-screen items-center gap-4 px-4 sm:px-6 lg:px-8">
            {layout.backPath && (
              <IconLink
                href={layout.backPath}
                onClick={() => {
                  logButtonClick({
                    buttonName: "Go back",
                    linkHref: layout.backPath,
                    containerName: "Main",
                    pageName: pageTitle,
                  })
                }}
                icon={<ArrowLeftIcon />}
                label="Go back"
                size={8}
                theme="bordered"
              />
            )}

            <h1
              data-cy="page-title"
              className="text-lg font-bold leading-tight text-otto-grey-900 md:text-2xl"
            >
              {pageTitle}
            </h1>

            <div className="ml-auto">{layout.actionButton}</div>
          </div>
        </header>
      )}

      {layout.type === "simple" && (
        <header className="sr-only">
          <h1 data-cy="page-title">{pageTitle}</h1>
        </header>
      )}

      <main className={classNames("main", layout.type)}>
        <div
          className={classNames("mx-auto h-full", {
            "max-w-screen px-4 sm:px-6 lg:px-8": !noMaxContentWidth,
            "py-4": !noPadding,
          })}
        >
          <ErrorBoundary>{children}</ErrorBoundary>
        </div>
      </main>
    </div>
  )
}

export default ScreenTemplate
